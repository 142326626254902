import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import Works from "../components/Works"


const WorkPage = () => (
  <Layout>
    <Seo title="Work" />
    <Works/>
  </Layout>
)

export default WorkPage
